import React from 'react';
import './App.css';
import Grid from './grid.js';

function App() {
  

  return (
    <div className="App">
      <Grid />
    </div>
  );
}

export default App;
